// Products.js
import React from 'react';
import logo from './logo.png';

const Products = () => {
  return (
	    <div style={containerStyle}>

      <div style={textContainer}>
        <h1 style={textStyle}><strong>OFERTA</strong></h1>
              <ol>
                  <li><strong>Instalacje Wodno-Kanalizacyjne:</strong> Firma Eko-Instal oferuje pełen zakres usług związanych z projektowaniem, montażem i konserwacją instalacji wodociągowych oraz kanalizacyjnych. Bezpieczeństwo, trwałość i zgodność z przepisami to priorytety przy realizacji każdego projektu.</li>
                  <li><strong>Instalacje Cieplne:</strong> Specjaliści Eko-Instal projektują i montują efektywne systemy grzewcze, dbając o optymalne wykorzystanie energii. Oferujemy rozwiązania dostosowane do indywidualnych potrzeb klientów, zapewniając komfort cieplny przez wiele lat.</li>
                  <li><strong>Instalacje Gazowe:</strong> Nasza firma zajmuje się instalacjami gazowymi, łącząc bezpieczeństwo i nowoczesne technologie. Dostarczamy kompleksowe usługi obejmujące projektowanie, montaż, a także regularne przeglądy i konserwacje.</li>
                  <li><strong>Instalacje Klimatyzacyjne:</strong> Eko-Instal oferuje rozwiązania klimatyzacyjne, dostosowane do różnorodnych potrzeb klientów. Projektujemy i instalujemy systemy, które zapewniają optymalne warunki termiczne i komfort powietrzny w każdym pomieszczeniu.</li>
              </ol>
                    <div style={logoContainer}>
			          <img src={logo} alt="Logo" style={logoStyle} />
      </div>
              </div>
    </div>

  );
};

// Styles
const containerStyle = {
  display: 'flex',
  alignItems: 'top',
  padding: '20px',
};

const logoContainer = {
  marginLeft: '20px',
};

const logoStyle = {
  width: '300px', // Adjust the logo width as needed
  height: '300px', // Adjust the logo height as needed
};

const textContainer = {
	textAlign: 'center',
	};

const textStyle = {
  fontSize: '24px',
};


export default Products;
