// Home.js
import React from 'react';
import logo from './logo.png';

const Home = () => {
  return (
    <div style={containerStyle}>

      <div style={textContainer}>
        <h1 style={textStyle}><strong>EKO-INSTAL</strong></h1>
<p>To renomowana firma specjalizująca się w kompleksowym wykonawstwie instalacji wodno-kanalizacyjnych, cieplnych, gazowych i klimatyzacyjnych. Zespół doświadczonych specjalistów skupia się na dostarczaniu rozwiązań najwyższej jakości, zgodnych z najnowszymi standardami branżowymi.</p>
<h1 style={textStyle}><strong>Dlaczego Wybrać Nas?</strong></h1>
<p><strong>Doświadczenie i Kompetencje:</strong> Zespół Eko-Instal to wysoko wykwalifikowani specjaliści z wieloletnim doświadczeniem w branży.</p>
 <p><strong>Nowoczesne Technologie:</strong> Stawiamy na innowacyjne rozwiązania, korzystając z najnowszych technologii dostępnych na rynku.</p>
<p><strong>Bezpieczeństwo i Zgodność Norm:</strong> Dbamy o bezpieczeństwo naszych klientów, stosując się do wszelkich norm i przepisów obowiązujących w branży.</p>
<p><strong>Indywidualne Podejście:</strong> Rozumiemy, że każdy projekt i każdy klient są unikalni. Dlatego dostosowujemy nasze usługi do indywidualnych potrzeb i oczekiwań.</p>

            <div style={logoContainer}>
	          <img src={logo} alt="Logo" style={logoStyle} />
	          </div>
      </div>
    </div>
  );
};

// Styles
const containerStyle = {
  display: 'flex',
  alignItems: 'top',
  padding: '20px',
};

const logoContainer = {
  marginLeft: '20px',
};

const logoStyle = {
  width: '300px', // Adjust the logo width as needed
  height: '300px', // Adjust the logo height as needed
};

const textContainer = {
	textAlign: 'center',
	};

const textStyle = {
  fontSize: '24px',
};

export default Home;

