// Footer.js
import React from 'react';
import { Navbar, Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="md" fixed="bottom">
      <Container>
        <Navbar.Brand>&copy; {new Date().getFullYear()} EKO-INSTAL</Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Footer;
