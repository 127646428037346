
// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import Home from './components/Home';
import Products from './components/Products';
import Footer from './components/Footer';
import Contact from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo_small from './logo_small.png';

const App = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  return (
    <Router>
      <div className="App">
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand as={Link} to="/">
            <div style={logoContainer}>
        			<img src={logo_small} alt="logo_small" style={logoStyle} />
      		</div>
              EKO-INSTAL Daniel Zabłocki
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={handleNavCollapse}
            />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/">
                  O Firmie
                </Nav.Link>
                <Nav.Link as={Link} to="/products">
                  Produkty
                </Nav.Link>
                <Nav.Link as={Link} to="/contact">
                  Kontakt
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>

        </Navbar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>

  );
};

const logoContainer = {
  marginLeft: '20px',
};

const logoStyle = {
  width: '50px', // Adjust the logo width as needed
  height: '50px', // Adjust the logo height as needed
};

export default App;

