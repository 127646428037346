// Contact.js

import logo from './logo.png';
import React, { useEffect } from 'react';

const Contact = () => {


  return (
	    <div style={containerStyle}>
      <div style={textContainer}>
        <h1 style={textStyle}><strong>Kontakt</strong></h1>
<p><strong>Telefon:</strong> 📞 504 127 622</p>
<p><strong>E-mail:</strong> 📧 danyz7909@gmail.com</p>
<p><strong>Adres:</strong> 📌Świdry 110 A, 21-400 Świdry</p>
      <div style={logoContainer}>
        <img src={logo} alt="Logo" style={logoStyle} />
      </div>
              </div>

   </div>

  );
};

// Styles
const containerStyle = {
  display: 'block',
  padding: '20px',
};
const logoContainer = {
  marginLeft: '20px',
};

const logoStyle = {
  width: '300px', // Adjust the logo width as needed
  height: '300px', // Adjust the logo height as needed
};

const textContainer = {
	textAlign: 'center',
	marginLeft: '10px',
	};

const textStyle = {
  fontSize: '24px',
};


export default Contact;
